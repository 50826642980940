@import '~@styles/variables.scss';

.roles-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-top: 4em;

  .competence-card {
    box-shadow: none;
    position: relative;
    margin: 0;
    border-radius: 0;
    border-bottom: $separator-border;
    padding: 0;
    font-size: $font-size-sm;

    &:hover {
      .removeThis {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        color: $color-red;

        * {
          cursor: pointer;
        }

        &.hassub {
          top: 20px;
          right: 40px;
        }
      }
    }

    .removeThis {
      display: none;
    }

    &.employee {
      .button {
        margin-top: 10px;
        margin-right: 10px;
      }
    }

    .sub {
      h3 {
        margin-bottom: 0;
        border-bottom: 0;
        text-indent: 40px;
      }

      > .competence-card {
        border: none;
        padding-left: 30px;
        background-color: #fafafa;
      }
    }
  }

  &__report-link {
    width: 100%;
    font-size: $font-size-sm;
    text-align: right;

    a {
      color: $color-grey-60;
    }
  }
  &__loading {
    margin-left: 20px;
  }

  &__role-section {
    flex-direction: column;

    &__header {
      display: flex;
      flex-direction: row;

      @media only screen and (max-width: $desktop-small-width) {
        flex-direction: column;
      }
    }

    h2 {
      flex: 1 1 auto;
      margin-top: 0;
      font-weight: 500;
      margin-bottom: 0.8em;
      margin-left: 0;
    }

    ul.tabs {
      float: right;
      flex: 0 0 auto;
      margin-top: 0;
      margin-right: 20px;
      margin-bottom: 20px;
      padding-left: 0;
      font-size: $font-size-m;

      li {
        padding-top: 0;
      }
    }

    margin-bottom: 30px;
  }

  &__role,
  &__role__loader {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 0;
    &.first {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &.last {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    background: white;

    svg {
      flex: 0 0 auto;
      width: 500px;
      height: 42px;
      margin: auto 0;
      margin-left: 12px;
    }
  }

  &__role__loader {
    overflow: hidden;
    height: 52px;
  }

  &__role-header {
    display: flex;
    align-items: center;
    border-bottom: $separator-border;
    padding: 0.5em 1.2em;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }
    &.focus-visible {
      outline: 2px dashed gray;
    }
  }

  &__role-expand-button {
    margin-right: 10px;
    color: $color-grey-40;
  }

  &__role-title {
    flex: 1 1 0;
    color: #0a0a0a;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
  }

  &__role-progress {
    flex: 0 1 100px;
    margin-right: 20px;

    .progress {
      font-weight: 400;
      font-size: $font-size-s;
    }
  }

  &__competence {
    display: flex;
    flex-direction: column;
    border-bottom: $separator-border;
    padding: 10px;
    padding-left: 20px;

    &:last-child {
      border-bottom: none;
    }

    .signed-info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: $font-size-s;

      svg {
        height: auto;
        color: #999;
      }

      div {
        flex: 0 0 auto;
        margin-right: 20px;
      }
    }
  }

  &__competence-status {
    flex: 0 0 auto;
    margin-right: 10px;

    &::after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border: 1px solid grey;
      border-radius: 50%;
    }

    &--not-started::after {
      border-color: red;
    }

    &--in-progress::after {
      border-color: yellow;
    }

    &--completed::after {
      border-color: green;
    }
  }

  &__competence-title {
    display: flex;
    flex: 0 0 auto;
    align-items: baseline;
    margin-right: 20px;
    font-size: 15px;
  }

  &__competence-description {
    flex: 1 1 auto;
    font-size: 13px;
  }
}
