@import '~@styles/variables.scss';

.progress {
  color: $color-grey-50;
  width: 100%;
  font-weight: 600;
  font-size: $font-size-m;
  line-height: 24px;
  white-space: nowrap;

  &__progress {
    display: inline-block;
    margin-right: 12px;
    line-height: 24px;
    vertical-align: middle;
  }

  &__label {
    display: inline-block;
    font-weight: 500;
    font-size: $font-size-s;
    line-height: 24px;
    vertical-align: middle;
  }

  &__bar-overall {
    max-width: 300px;
    height: 7px;
    margin-top: 2px;
    border-radius: 5px;
    background-color: $color-progressbar-fill-empty;

    &--full-width {
      width: 100%;
      max-width: 100%;
    }
  }

  &__bar-complete {
    height: 7px;
    border-radius: 5px;
    background-color: $color-progressbar-fill;
  }
}
