@import '~@styles/variables.scss';

.activity-list {
  flex: 1 1 auto;
  margin-bottom: 50px;
  font-size: $font-size-m;

  &__header {
    padding: 15px;
    font-weight: 700;
    font-size: $font-size-l;
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 4px;

    &__tab {
      flex: 0 0 auto;
      margin: 0 4px;
      padding: 6px 14px;
      cursor: pointer;

      &:hover {
        border-bottom: 1px solid var(--color-header-link-underline);
      }

      &.active {
        border-bottom: 2px solid var(--color-header-link-underline);
        color: $color-black;
      }
    }
  }

  &__element {
    position: relative;
    display: flex;
    align-items: center;
    margin: 16px 0;
    &.past{
      color: #D04B40;
      .activity-list__circle {
        border-color: #cf1322;
        background-color: #ffccc7;
      }
    }
    &.not-past{
      .activity-list__circle {
        border-color: #EEAF15;
      }
    }
  }

  &__loader {
    width: 400px;
    height: 40px;
  }

  &__activity-description {
    font-weight: 500;
    font-size: $font-size-sm;
    &__link {
      color: #D04B40!important;
    }
  }

  &__activity-timestamp {
    float: right;
    margin-right: 20px;
    margin-left: auto;
    font-size: $font-size-s;
    white-space: nowrap;

    &-wrapper {
      overflow: visible;
      flex: 0 0 auto;
      width: 96px;
    }
  }

  &__circle {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    margin: auto;
    border: 2px solid #a0a0a0;
    border-radius: 50%;
    background: #d4d4d4;

    &-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      width: 16px;
      margin: -16px 0;
      margin-right: 30px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        z-index: -1;
        display: block;
        width: 0;
        border-left: 1px solid #a0a0a0;
      }
    }
  }

  &__element:first-of-type &__circle-wrapper::after {
    top: 50%;
  }

  &__element:last-of-type &__circle-wrapper::after {
    bottom: 50%;
  }

  &__person-avatar {
    $avatar-size: 30px;

    flex: 0 0 auto;
    width: $avatar-size;
    height: $avatar-size;
    margin-right: 10px;
    border: 1px solid #979797;
    background-color: #d8d8d8 !important;

    .person-avatar__initials {
      font-size: $font-size-sm !important;
    }
  }

  &__competence-link {
    &,
    &:active,
    &:visited {
      color: inherit;
      font-weight: inherit;
      text-decoration: none;
    }

    &:hover {
      color: var(--color-main-dark);
      text-decoration: underline;
    }
  }

  &__person-name {
    &,
    &:active,
    &:visited {
      color: var(--color-main-dark);
      font-weight: 700;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
