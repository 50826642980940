@import '~@styles/variables.scss';

.course-details-wrapper {
  margin-top: 35px;
  padding: 0 20px;
}

.course-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  margin-top: 20px;

  &__files {
    margin: 40px 0;
  }

  &__file-button {
    display: block;
    margin: 10px 10px;
    padding: 12px 16px;
    background-color: $color-grey-90;
    color: $color-black;
    font-size: $font-size-m;

    i {
      margin-right: 8px;
      color: $color-black;
      font-size: $font-size-l;
    }
  }

  &__status {
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: $font-size-m;
  }

  &__content {
    width: 80%;

    &.success {
      background: $color-completed;
    }
  }

  &__module {
    box-shadow: 1px 2px 3px #ccc;
    margin-bottom: 30px;
    border-radius: 6px;
    padding: 20px;
    background: white;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    > * {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 100px;
      }
    }
  }

  &__cover {
    display: flex;
    height: 400px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  &__header-title-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    min-width: 0;

    .course-preview__header-start {
      display: inherit;
      margin-top: 20px;
    }
  }

  &__header-title {
    overflow: hidden;
    display: flex;
    color: black;
    font-weight: 700;
    font-size: $font-size-l;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__header-meta-container {
    display: flex;
  }

  &__header-meta-item {
    display: flex;
    align-items: baseline;
    color: $color-grey-50;
    font-weight: bold;
    font-size: $font-size-s;
    line-height: 1.5;

    &:not(:first-child) {
      margin-left: 30px;
    }

    i {
      margin-right: 10px;
    }
  }

  &__description {
    line-height: 1.2;
  }

  &__events {
    margin-top: 40px;

    .course-event-details {
      border-top: 1px solid #ccc;
      padding: 20px 0;

      button {
        float: right;
        margin-top: -10px;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      padding: 12px 15px;
      font-weight: 400;

      svg{
        margin-left: 20px;
      }
    }
  }

  &__action-button {
    &:not(:last-child) {
      margin-right: 10px;
    }

    &--signed {
      background-color: $color-grey-70;
      cursor: default;

      i {
        margin-right: 4px;
      }
    }
  }
}
