@import '~@styles/variables.scss';

.course-catalog-wrapper {
  display: flex;
  flex-direction: column;

  .course-catalog {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
    flex-grow: 1;
    height: 100%;

    &__courses-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 3px 0px;
    }

    &__courses {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 8em;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr 1fr 1fr;
      @media screen and (max-width: $course-catalog-two-column-layout) {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 6px;
      }

      @media screen and (max-width: $course-catalog-one-column-layout) {
        grid-template-columns: 1fr;
        margin-bottom: 3px;
      }
    }

    .courses_with_sidebar {
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: $course-catalog-one-column-layout) {
        grid-template-columns: 1fr;
        margin-bottom: 3px;
      }
    }

    &__course-card-wrapper,
    &__course-card-loader-wrapper {
      display: flex;
      align-items: stretch;

      @media screen and (max-width: $desktop-small-width) {
        margin-bottom: 6px;
      }

      @media screen and (max-width: $course-catalog-one-column-layout) {
        margin-bottom: 3px;
      }
    }

    &__course-card-wrapper.new_card_style {
      flex: 0 0 35%;
      padding-bottom: 0.6em;
      margin-bottom: 0;
      min-width: 250px;
      margin-right: 0.3em;
    }

    @media screen and (max-width: $tablet-width-course-catalog) {
      flex-direction: column;
      width: 100%;

      &__courses {
        border-top: none;
      }

      &__courses-wrapper {
        margin: 0;
        padding: 22px;
      }
    }

    &__courseevents-wrapper {
      margin-top: 0;
      padding: 0 10px;
    }

    &__course-calendar-card-wrapper {
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;
      margin-bottom: 30px;
      padding: 0.9em;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    &__calendar-title-month {
      margin-top: 60px;
      margin-bottom: 20px;
      text-transform: capitalize;

      &:first-child {
        margin-top: 30px;
      }
    }

    &__no-results {
      margin: auto;
      color: $color-grey-60;
      font-size: $font-size-l;

      &__term {
        font-style: italic;
        font-weight: bold;
      }
    }
  }
}

.course-catalog-wrapper.desktop {
  /*Prevent a possible scrollbr from chaging the width of the page
  and pushing the content
  The padding are the space where the scrollbar resides or empty space if scroll is not present
  */
  margin-right: unquote('calc(-1 * (100vw - 100%))');
}
